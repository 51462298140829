<template>
    <div id="DonateOverview" class="overview-style">
        <section class="filter-section">
            <ElForm
                :label-position="store.getters.labelPosition"
                label-width="90px"
                :model="donateFilter"
            >
                <div class="left">
                    <ElFormItem label="検索">
                        <ElInput
                            v-model.lazy="donateFilter.keyword"
                            @change="
                                updateQuery([['keyword', donateFilter.keyword]])
                            "
                            @keypress.enter="
                                updateQuery([['keyword', donateFilter.keyword]])
                            "
                            @submit.prevent
                        ></ElInput>
                    </ElFormItem>
                    <ElFormItem label="フィルター">
                        <ElCheckbox
                            v-model="member"
                            label="界員"
                            @change="isMemberClick"
                        ></ElCheckbox>
                        <ElCheckbox
                            v-model="nonMember"
                            label="非界員"
                            @change="isMemberClick"
                        ></ElCheckbox>
                    </ElFormItem>
                </div>
                <div class="right">
                    <ElFormItem label="ソート">
                        <ElSelect
                            v-model="donateFilter.orderStr"
                            placeholder="ソート"
                            @change="
                                updateQuery([
                                    ['orderStr', donateFilter.orderStr],
                                ])
                            "
                        >
                            <ElOption
                                v-for="item in sortOptions"
                                :key="item.label"
                                :label="item.label"
                                :value="item.value"
                            ></ElOption>
                        </ElSelect>
                    </ElFormItem>
                </div>
            </ElForm>
        </section>
        <section class="table-section">
            <ElTable
                :data="
                    donateList.slice(
                        (currentPage - 1) * pageSize,
                        currentPage * pageSize
                    )
                "
                style="width: 100%; font-size: 12px"
            >
                <ElTableColumn prop="isMember" label="界員" width="60" fixed>
                    <template #default="scope">
                        <span v-if="scope.row.isMember"> 界員 </span>
                        <span v-else> 非界員</span>
                    </template>
                </ElTableColumn>
                <ElTableColumn label="名前" width="100" fixed>
                    <template #default="scope">
                        <router-link
                            v-if="scope.row.userId"
                            :to="{
                                name: 'MemberDetails',
                                params: { userId: scope.row.userId },
                            }"
                        >
                            {{ scope.row.name }}
                        </router-link>
                        <span v-else>{{ scope.row.name }}</span>
                    </template>
                </ElTableColumn>
                <ElTableColumn
                    prop="email"
                    label="メールアドレス"
                    fixed
                ></ElTableColumn>
                <ElTableColumn
                    prop="phone"
                    label="電話番号"
                    fixed
                ></ElTableColumn>
                <ElTableColumn
                    prop="createdAt"
                    label="おつなぎ徳積みの日付"
                    width="120"
                ></ElTableColumn>
                <ElTableColumn label="おつなぎ徳積み額" width="100"
                    ><template #default="scope">
                        {{ dollarFormatter(scope.row.singleAmount) }}
                    </template></ElTableColumn
                >
                <ElTableColumn label="おつなぎ徳積みの総額" width="100"
                    ><template #default="scope">
                        {{ dollarFormatter(scope.row.totalAmount) }}
                    </template></ElTableColumn
                >
                <ElTableColumn prop="subCategory" label="備考"></ElTableColumn>
            </ElTable>
        </section>
        <section class="pagination-section">
            <ElPagination
                v-model:currentPage="currentPage"
                v-model:page-size="pageSize"
                :page-sizes="[10, 15, 20, 30]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="donateList.length"
            >
            </ElPagination>
        </section>
    </div>
</template>
<script>
export default {
    name: 'DonateOverview',
}
</script>
<script setup>
import { reactive, ref, onMounted } from 'vue'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getDonateOverview } from '@/service/api'
import { initQuery, updateQuery } from '@/utils/query'
import { dollarFormatter } from '@/utils/common'
const store = useStore()
const route = useRoute()

onBeforeRouteUpdate((to) => {
    initDonateList(to.query)
})
onMounted(() => {
    initQuery(donateFilter)
    if (route.query.isMember === true) member.value = true
    else if (route.query.isMember === false) nonMember.value = true
})
initDonateList(route.query)
const currentPage = ref(1)
const pageSize = ref(10)
const sortOptions = [
    { value: 'createdAt,desc', label: 'おつなぎ徳積みの日付 降順' },
    { value: 'createdAt,asc', label: 'おつなぎ徳積みの日付 昇順' },
    { value: 'singleAmount,desc', label: 'おつなぎ徳積み額 降順' },
    { value: 'singleAmount,asc', label: 'おつなぎ徳積み額 昇順' },
    { value: 'totalAmount,desc', label: 'おつなぎ徳積みの総額 降順' },
    { value: 'totalAmount,asc', label: 'おつなぎ徳積みの総額 昇順' },
    { value: 'name,asc', label: '氏名 A-Z' },
    { value: 'name,desc', label: '氏名 Z-A' },
]
const donateFilter = reactive({
    keyword: '',
    isMember: '',
    orderStr: '',
})
const member = ref(false)
const nonMember = ref(false)
const donateList = ref([])
async function initDonateList(query) {
    let res = await getDonateOverview(query)
    donateList.value = res.data
}
function isMemberClick() {
    if (member.value && nonMember.value) updateQuery([['isMember']])
    else if (member.value) updateQuery([['isMember', true]])
    else if (nonMember.value) updateQuery([['isMember', false]])
    else updateQuery([['isMember']])
}
</script>
<style lang="scss" scoped></style>
